<template>
  <div>
    <div class="card">
      <div class="p-2 border-bottom">
        <h3 class="mb-0">
          <i class="fas fa-ellipsis-h-alt" /> Menu
        </h3>
      </div>

      <div class="p-2">
        <div class="row">
          <div
            v-for="(item , index ) in productlist.Menu"
            :key="item.name"
            class="col-sm-6 col-md-4 col-lg-3 my-1"
          >
            <div class="game-menu-main w-100 p-1 h-100">
              <div class="d-flex flex-row-reverse">
                <b-form-checkbox
                  v-model="checkmenu[index]"
                  switch
                  @change="UpdateMenu(checkmenu[index] , item.id)"
                />
              </div>
              <div class="d-flex flex-row justify-content-md-between align-items-center py-1">
                <img
                  :src="`${item.img_url}`"
                  alt="games-logo"
                >
                <div class="px-1 text-right">
                  <div class="game-menu-main__name h4">{{ item.name_en.toUpperCase() }}</div>
                  <div class="game-menu-main__name h4">{{ item.name }}</div>
                </div>
                
              </div>
            </div>
            <!-- <div class="game-menu-items">
              <div class="chx-switch-sm">
                <b-form-checkbox
                  v-model="checkmenu[index]"
                  switch
                  @change="UpdateMenu(checkmenu[index] , item.id)"
                />
              </div>
              <img
                :src="`${item.img_url}`"
                alt="games-logo"
              >
              <div class="game-menu-items__name text-center h4">
                {{ item.name }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-2 border-bottom">
        <h3><i class="fal fa-gamepad" /> Casino</h3>
      </div>

      <div class="p-2">
        <div class="row">
          <div
            v-for="(item , index) in productlist.Casino"
            :key="item.productName"
            class="col-md-2 my-1"
          >
            <div class="game-menu-items">
              <div class="chx-switch-sm">
                <b-form-checkbox
                  v-model="checkcasino[index]"
                  switch
                  @change="UpdateProduct(checkcasino[index] , item.id)"
                />
              </div>
              <img
                :src="item.img_url"
                alt="games-logo"
              >
              <div class="game-menu-items__name text-center h4">
                {{ item.productName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-2 border-bottom">
        <h3><i class="fal fa-gamepad" /> SLOT Gaming</h3>
      </div>

      <div class="p-2">
        <div class="row">
          <div
            v-for="(item , index) in productlist.Slot"
            :key="item.productName"
            class="col-md-2 my-1"
          >
            <div class="game-menu-items">
              <div class="chx-switch-sm">
                <b-form-checkbox
                  v-model="checkslot[index]"
                  switch
                  @change="UpdateProduct(checkslot[index] , item.id)"
                />
              </div>
              <img
                :src="item.img_url"
                alt="games-logo"
              >
              <div class="game-menu-items__name text-center h4">
                {{ item.productName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-2 border-bottom">
        <h3><i class="fal fa-gamepad" /> Sport</h3>
      </div>

      <div class="p-2">
        <div class="row">
          <div
            v-for="(item , index) in productlist.Sport"
            :key="item.productName"
            class="col-md-2 my-1"
          >
            <div class="game-menu-items">
              <div class="chx-switch-sm">
                <b-form-checkbox
                  v-model="checksport[index]"
                  switch
                  @change="UpdateProduct(checksport[index] , item.id)"
                />
              </div>
              <img
                :src="item.img_url"
                alt="games-logo"
              >
              <div class="game-menu-items__name text-center h4">
                {{ item.productName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-2 border-bottom">
        <h3><i class="fal fa-gamepad" /> Card</h3>
      </div>

      <div class="p-2">
        <div class="row">
          <div
            v-for="(item , index) in productlist.Card"
            :key="item.productName"
            class="col-md-2 my-1"
          >
            <div class="game-menu-items">
              <div class="chx-switch-sm">
                <b-form-checkbox
                  v-model="checkcard[index]"
                  switch
                  @change="UpdateProduct(checkcard[index] , item.id)"
                />
              </div>
              <img
                :src="item.img_url"
                alt="games-logo"
              >
              <div class="game-menu-items__name text-center h4">
                {{ item.productName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-2 border-bottom">
        <h3><i class="fal fa-gamepad" /> Poker</h3>
      </div>

      <div class="p-2">
        <div class="row">
          <div
            v-for="(item , index) in productlist.Poker"
            :key="item.productName"
            class="col-md-2 my-1"
          >
            <div class="game-menu-items">
              <div class="chx-switch-sm">
                <b-form-checkbox
                  v-model="checkpoker[index]"
                  switch
                  @change="UpdateProduct(checkpoker[index] , item.id)"
                />
              </div>
              <img
                :src="item.img_url"
                alt="games-logo"
              >
              <div class="game-menu-items__name text-center h4">
                {{ item.productName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-2 border-bottom">
        <h3><i class="fal fa-gamepad" /> Trading</h3>
      </div>

      <div class="p-2">
        <div class="row">
          <div
            v-for="(item , index) in productlist.Trading"
            :key="item.productName"
            class="col-md-2 my-1"
          >
            <div class="game-menu-items">
              <div class="chx-switch-sm">
                <b-form-checkbox
                  v-model="checktrading[index]"
                  switch
                  @change="UpdateProduct(checktrading[index] , item.id)"
                />
              </div>
              <img
                :src="item.img_url"
                alt="games-logo"
              >
              <div class="game-menu-items__name text-center h4">
                {{ item.productName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'GameSetting',
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isCasino: true,
      listGame: [
        { name: 'สล็อตเกม', picture: 'https://wallet.power89th.com/games/egame/Slotxo.png', status: true },
        { name: 'คาสิโนสด', picture: 'https://wallet.power89th.com/games/egame/Mannaplay.png', status: true },
        { name: 'กีฬา', picture: 'https://wallet.power89th.com/games/egame/Mannaplay.png', status: true },
        { name: 'หวย', picture: 'https://wallet.power89th.com/games/egame/Mannaplay.png', status: true },
        { name: 'ยิงปลา', picture: 'https://wallet.power89th.com/games/egame/Mannaplay.png', status: true },
        { name: 'เกมการ์ด', picture: 'https://wallet.power89th.com/games/egame/Mannaplay.png', status: true },
        { name: 'เกมโป๊กเกอร์', picture: 'https://wallet.power89th.com/games/egame/Mannaplay.png', status: true },
        { name: 'เทรดดิ้ง', picture: 'https://wallet.power89th.com/games/egame/Mannaplay.png', status: true },
      ],
      productlist: null,
      checkmenu: [],
      checkcasino: [],
      checkslot: [],
      checksport: [],
      checkcard: [],
      checkpoker: [],
      checktrading: [],
    }
  },
  mounted() {
    this.Getlist()
  },
  methods: {
    UpdateMenu(check, id) {
      const obj = {
        id,
        switchs: check,
      }
      this.$http
        .post('/menu/update', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.Success('บันทึกสำเร็จ')
        })
        .catch(error => console.log(error))
    },
    UpdateProduct(check, id) {
      const obj = {
        id,
        switchs: check,
      }
      this.$http
        .post('/product/update', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.Success('บันทึกสำเร็จ')
        })
        .catch(error => console.log(error))
    },
    Getlist() {
      this.$http
        .get('/product/list')
        .then(response => {
          this.productlist = response.data
          console.log(response.data);
          // Menu
          this.productlist.Menu.forEach((element, index) => {
            // console.log(element)
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.checkmenu[index] = true
              } else {
                this.checkmenu[index] = false
              }
            } else {
              this.checkmenu[index] = false
            }
          })
          // Casino
          this.productlist.Casino.forEach((element, index) => {
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.checkcasino[index] = true
              } else {
                this.checkcasino[index] = false
              }
            } else {
              this.checkcasino[index] = false
            }
          })
          // slot
          this.productlist.Slot.forEach((element, index) => {
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.checkslot[index] = true
              } else {
                this.checkslot[index] = false
              }
            } else {
              this.checkslot[index] = false
            }
          })
          // Sport
          this.productlist.Sport.forEach((element, index) => {
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.checksport[index] = true
              } else {
                this.checksport[index] = false
              }
            } else {
              this.checksport[index] = false
            }
          })
          // Card
          this.productlist.Card.forEach((element, index) => {
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.checkcard[index] = true
              } else {
                this.checkcard[index] = false
              }
            } else {
              this.checkcard[index] = false
            }
          })
          // Poker
          this.productlist.Poker.forEach((element, index) => {
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.checkpoker[index] = true
              } else {
                this.checkpoker[index] = false
              }
            } else {
              this.checkpoker[index] = false
            }
          })
          // Trading
          this.productlist.Trading.forEach((element, index) => {
            const userAgentId = this.userData.agent_id
            if (element.agent_use) {
              const agentUseArray = element.agent_use.split(',').map(agentId => parseInt(agentId.trim(), 10))
              if (agentUseArray.includes(userAgentId)) {
                this.checktrading[index] = true
              } else {
                this.checktrading[index] = false
              }
            } else {
              this.checktrading[index] = false
            }
          })
        })
        .catch(error => console.log(error))
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
